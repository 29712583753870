import injectSheet from "react-jss";
import React, { useState, useEffect, PureComponent } from "react";
import { Scrollama, Step } from "react-scrollama";
import { Box, Flex, Text } from "rebass";
import SEO from "../components/seo";
import { ThemeProvider } from "emotion-theming";
import theme from "../themes/theme.js";
import { createGlobalStyle } from "styled-components";
import parksDiversityFile from "../data/NYC_Parks_Diversity.pdf";
import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import ParksMap from "../components/parksmap.js";
import DeckGL, { FlyToInterpolator } from "deck.gl";
import { Spring } from "react-spring/renderprops";
import Image from "../components/demoImage.js";
import { Link } from "gatsby";
import "semantic-ui-css/semantic.min.css";
import { Popup } from 'semantic-ui-react';
import { GrContact } from 'react-icons/gr';



const metaData = {
  title: [
    <span>Do Urban Parks Promote </span>,
    <br />,
    <span>Racial Diversity?</span>,
  ],
  titleShort: "Do Urban Parks Promote Racial Diversity?",
  subtitle: "Evidence from New York City",
  id: "nyc-parks-diversity",
  year: 2020,
  authors: [],
  abstract:
    "While policymakers and urban planners often praise public space for promoting racial diversity, empirically it remains unclear whether these places play a role in creating diverse social environments for the city's residents. Focusing on parks in New York City as the exemplar of modern public space, I estimate the causal relationship between access to parks and individually experienced diversity. To do so, I introduce a measure of racial diversity that captures one's level of exposure to diverse others in places visited on a daily basis, utilizing a novel dataset featuring individual GPS tracking data for more than 60 thousand Twitter users in the New York metro area. My empirical strategy relies on obtaining a time-varying measure of access to parks that incorporates information about ongoing construction and repair works across the city. The results show that additional 10 acres of parkland within the 5km radius from home increase individual chances of encounters with other racial/ethnic groups by 1 percentage point. I also provide evidence to suggest that park accessibility affects the diversity of white and black residents differently: for parks located closer to home, the effect appears to be more pronounced for whites than blacks, indicating that parks in the majority-white neighborhoods are able to attract a broader range of visitors compared to the local parks in black neighborhoods.",
  pdflink: parksDiversityFile,
  tags: [
    { name: "Job Market Paper", color: "oranLight" },
    { name: "Working Paper" },
  ],
  open: 1,
};


const textSize = ["18px", "22px", "24px"];
const textLineHeight = ["22px", "26px", "28px"];
const sectionTextSize = ["24px", "28px", "30px"];
const sectionLineHeight = ["28px", "30px", "34px"];
const quoteBoxWidth = ["80vw","80vw","55vw"];
const topPad = ["10px","10px","10px"];
const sectionPaddingTop = ["15px","15px","30px"];
const sectionPaddingLeft = ["20px","20px","40px"];
const styles = {
  navbar: {
    position: "fixed",
    display: "flex",
    top: 0,
    right: 0,
    zIndex: 1,
    "& a": {
      display: "block",
      fontSize: "20px",
      padding: "20px",
    },
  },
  pageTitle: {
    textAlign: "center",
    fontSize: 30,
    margin: "110px 0 10px",
  },
  pageSubtitle: {
    textAlign: "center",
    fontSize: "24px",
    color: "#888",
  },
  graphicContainer: {
    // padding: '40vh 2vw 70vh',
    // backgroundColor: 'blue',
    display: "flex",
    justifyContent: "space-between",
  },
  graphic: {
    flexBasis: "60%",
    position: "sticky",
    width: "100%",
    height: "60vh",
    top: "20vh",
    backgroundColor: "#aaa",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      fontSize: "5rem",
      fontWeight: 700,
      textAlign: "center",
      color: "#fff",
    },
  },
  scroller: {
    flexBasis: "35%",
  },
};

const GlobalStyles = createGlobalStyle`
body {
 font-family: 'Source Sans', sans-serif;
 font-size: 15px;
 background-color: #93c89d;
}
q {
  quotes: "“" "”" "‘" "’";
}
q::before {
    content: open-quote;
}
q::after {
    content: close-quote;
}
`;

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "my-image-1-path.JPG" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    `}
    render={(data) => {
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage fluid={imageData} backgroundColor={`#040e18`}>
          <SEO
          title={metaData.titleShort + " " + metaData.subtitle}
          description={metaData.abstract}
          pathname={"/parks"}
          image={data.desktop.childImageSharp.resize}
          />
          <Box
            sx={{
              height: ["40vh", "50vh", "60vh"],
              // mt : '500px',
              // bg:'red',
              width: "100vw",
              height: "98vh",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Flex
              sx={{
                pt: 5,
                pl: [4,4,5],
                backgroundColor: "rgba(231, 242, 221, 0.5)",
                width: "100%",
                height: "100%",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                whiteSpace: "pre-line",
                color: "black",
              }}
            >
              <Box
                sx={{
                  fontFamily: "Source Serif Pro, serif",
                  fontWeight: 700,
                  pl: 0,
                  // bg: 'red',
                  pb: [1, "4px", 2],
                  lineHeight: ["34px", "46px", "54px"],
                  fontSize: ["34px", "38px", "45px"],
                }}
              >
                {metaData.title}
              </Box>
              <Box
                sx={{
                  fontFamily: "Roboto Slab, serif",
                  fontSize: ["20px", "24px", "30px"],
                  textAlign: "center",
                  fontWeight: 700,
                  color: "black",
                  mt: ["8px", "8px", 2],
                }}
              >
                {metaData.subtitle}
              </Box>
              <Flex
                sx={{
                  width: ["80vw","60vw","60vw"],
                  fontWeight: 500,
                  mt: [3,3,4],
                  // bg: "rgba(210, 229, 200, 0.75)",
                  lineHeight: ["18px", "21px", "24px"],
                  fontFamily: "Roboto, sans-serif",
                  fontSize: ["16px", "18px", "20px"],
                }}
              >
               <span>In my research paper <a href="https://tabbiasov.me/jmp" style={{ color: "#0E34A0" }}>[link]</a>, I examine individual GPS tracking data of
                more than 60 thousand Twitter users in New York to find out
                whether the city's parks help to make the casual encounters of
                its residents more racially diverse.</span>
              </Flex>
            </Flex>
          </Box>
        </BackgroundImage>
      );
    }}
  />
);

class ColorLegend extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0, reset: false };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  componentDidUpdate(prevProps) {
    if (
      (prevProps.page + 1 === this.props.page) &
      ((this.props.page === 5) |
        (this.props.page === 8) |
        (this.props.page === 7 | this.props.page === 9))
    ) {
      this.setState({ reset: true });
      setInterval(() => {
        this.setState({ reset: false });
      }, 10);
    }
  }
  render() {
    const colorGroups = [
      { name: "White", symbol: { fill: "rgb(29, 168, 39)" } },
      { name: "Black", symbol: { fill: "rgb(80, 128, 234)" } },
      { name: "Hispanic", symbol: { fill: "rgb(244, 133, 0)" } },
      { name: "Asian", symbol: { fill: "rgb(252, 75, 56)" } },
    ];
    const colorDots = [
      { name: "", symbol: { fill: "rgb(29, 168, 39)" } },
      { name: "", symbol: { fill: "rgb(80, 128, 234)" } },
      { name: "", symbol: { fill: "rgb(244, 133, 0)" } },
      { name: "One dot = 10 users", symbol: { fill: "rgb(252, 75, 56)" } },
    ];
    const emptyGroups = [
      {
        name: "Users' residential locations",
        symbol: { fill: "rgb(135, 151, 144)" },
      },
    ];
    const selectUser = [
      {
        name: "Visited locations (fake user)",
        symbol: { fill: "rgb(14, 52, 160)" },
      },
    ];


    const groups = [emptyGroups, colorGroups, selectUser, colorDots];

    const data = groups[
            this.props.page <= 5
              ? 0
              : this.props.page > 6
              ? this.props.page > 7
                ? (this.props.page > 8? 1: 3)
                : 2
              : 1
          ];
        
    if (this.props.page > 4 & this.props.page < 10) {

      return (
        <Spring
          delay={0}
          config={{ tension: 250, friction: 50, precision: 0.1 }}
          reset={this.state.reset}
          from={{ opacity: 0, width: 0.2 * this.state.width }}
          to={{ opacity: 1, width: Math.floor(0.34 * this.state.width) }}
        >
          {({ opacity, width }) => (
            data.map((item)=>{
              return (
              <Flex sx={{
                flexDirection: 'row',
                pr: item.name === "" ? 0 : 1,
                opacity,
                color: '#000000'
                // width: "100px",
                // bg: 'red'
              }}>
                <Box sx={{
                  mr: item.name === "" ? 0 : 1,
                  pt: "6px",
                  // bg: 'yellow',
                }}>
                <svg width="20">
                <circle fill={item.symbol.fill} cx="10" cy="10" r="6"/>
                </svg>
                </Box>
                <Box sx={{
                  fontFamily: "Roboto Condensed, sans-serif",
                  fontWeight: "normal",
                  pr: item.name === "" ? 0 : 1,
                  fontSize: "12pt",
                }}>
                  {item.name}
                </Box>
              </Flex>
              )
            })
            // <VictoryLegend
            //   x={0}
            //   y={5}
            //   width={width}
            //   gutter={this.props.page === 8 ? 0 : 10}
            //   // standalone={false}
            //   // title={this.state.reset}
            //   orientation="horizontal"
            //   // borderPadding={{ bottom: 0, right: 15 }}
            //   // gutter={20}
            //   style={{
            //     // border: { stroke: "#0E34A0", strokeWidth: 3},
            //     labels: {
            //       fontSize: 16,
            //       fontFamily: "Roboto Condensed, sans-serif",
            //       opacity,
            //       color: "red",
            //     },
            //   }}
            //   data={
            //     groups[
            //       this.props.page <= 5
            //         ? 0
            //         : this.props.page > 6
            //         ? this.props.page > 7
            //           ? (this.props.page > 8? 1: 3)
            //           : 2
            //         : 1
            //     ]
            //   }
            // />
          )}
        </Spring>
      );
    } else {
      return null;
    }
  }
}

class Demo extends PureComponent {
  state = {
    data: 0,
    steps: [3, 4, 5, 6, 7, 8, 9,10,11],
    progress: 0,
  };

  onStepEnter = ({ data }) => {
    this.setState({ data });
  };

  onStepExit = ({ direction, data }) => {
    if (direction === "up" && data === this.state.steps[0]) {
      this.setState({ data: 0 });
    }
  };

  onStepProgress = ({ progress }) => {
    this.setState({ progress });
  };

  render() {
    const { data, steps, progress } = this.state;
    const { classes } = this.props;
    const sectionHeaders = [
      'Quantifying the Everyday Encounters Using GPS Data from Social Media',
      'What Is the Role of Parks in Promoting Racially Diverse Encounters?'
    ];

    const slides = [
      <Box>
        How can we measure{" "}
        <u style={{ color: "#0E34A0" }}>
          <span>indivial exposure to racial diversity</span>
        </u>{" "}
        on a large scale? One solution is to harvest the vast geospatial data
        posted on social media to estimate the chances of casual encounters
        between different people.
      </Box>,
      <Box>
        I use a unique dataset of more than <u style={{ color: "#0E34A0" }}>
          15 million geotagged Twitter posts</u> {/* <Popup content='Add users to your feed' trigger={<span><u style={{ color: "#0E34A0" }}>
          15 million geotagged Twitter posts
        </u></span>}/> */}published between June and December 2014 and originating within the New
        York metro area.
        <br />
        <br />
        Overall, I obtain high-resolution GPS travel data for more than 60
        thousand city residents.
      </Box>,
      <Box>
        Then, I apply commerical{" "}
        <u style={{ color: "#0E34A0" }}>
          machine learning to process the profile images
        </u>{" "}
        and obtain the perceived ethic or racial attributes.
        <br />
        <br />
        Combined with the information on residence locations &mdash; also
        inferred from the patterns of online activity &mdash; this approach
        allows me to create a detailed representation of how different racial or
        ethnic communities co-locate daily in urban space.
      </Box>,
      <Box>
        To measure{" "}
        <u style={{ color: "#0E34A0" }}>individual experienced diversity</u>, I
        map the set of locations visited by each indivdual into a grid of
        rectangular cells (approx. 150 by 150 meters).
      </Box>,
      <Box>
        Then, for every location, I obtain the <u style={{ color: "#0E34A0" }}>representative racial and ethnic composition</u> of users that visited
        the same geographic cell in a particular month.
      </Box>,
      <Box>
        Finally, using the monthly frequencies of observed visits to each cell, for each
        user I estimate the experienced diversity index as the <u style={{ color: "#0E34A0" }}>
          probability of encountering a person of different race or ethnicity
        </u> on a random trip from home.
      </Box>,
      <Box>
        The central aim of this project is to establish whether the availability of park space has an effect on individual diversity.  
        <br/>
        <br/>
        In a cross-section setting, <u style={{ color: "#0E34A0" }}>residential selection presents a major threat for causal inference.</u> For example, if more sociable people value parks more highly, one would likely find that users with more parks in their neighborhood are more diverse, but this does not imply that parks increase diversity.
      </Box>,
      <Box>
      </Box>,
      <Box sx={{
        width:['80vw','80vw','70vw'],
        pb:['10vh','10vh','10vh'],
        "@media screen and (min-height: 1000px)": {
          pb: ["0","30vh","40vh"],
        },
        "@media screen and (min-height: 1200px)": {
          pb: ["0","40vh","50vh"],
        },
        // pt:'25vh',
      }}>
      In my paper, I circumvent the issues arising from static selection by <u style={{ color: "#0E34A0" }}>incorporating the data on temporal park service disruptions</u> due to ongoing contruction works. In particular, I construct a measure of park access that varies with time for each individual. This approach allows me to estimate the causal effects using a panel regression with individual fixed effects. 
      <br/>
      <br/>
      My results show that for White and Black/African American residents <u style={{ color: "#0E34A0" }}>additional 10 acres of park space within a 5 km radius from home increase individual chances of encounters with the members of other groups by 1 percentage point</u>. 
      <br/>
      <br/>
      <Box sx={{
        ml: ["0","-35px","-35px"],
        "@media screen and (min-height: 1200px)": {
          ml: ["0","-15px","-15px"],
        },
        mt: 1,
        pt: 3,
        fontSize: sectionTextSize,
        fontFamily: "Source Serif Pro, serif",
        fontWeight: 700,
        // bg:'red',
        pb: 4,
        mb: 0
      }}>Conclusion</Box>
      The present work emphasizes the role of parks – and, more generally, of public space – in nurturing diverse social environments and suggests a viable policy that can help cities reduce racial isolation. 
      <br/>
      <br/>
      <a href="https://tabbiasov.me/jmp" style={{ color: "#0E34A0" }}>[link]</a>
      </Box>
    ];
    const initialView = {
      viewState: {
        // longitude: -122.4,
        // latitude:37.7,
        latitude: 40.7777206,
        longitude: -73.9620116,
        zoom: 10.5,
        minZoom: 10,
        bearing: 0,
        pitch: 0,
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: "1200",
      },
    };

    const summaryView = {
      viewState: {
        // longitude: -122.4,
        // latitude:37.7,
        latitude: 40.723622709565575,
        longitude: -73.98776634518774,
        zoom: 14,
        minZoom: 10,
        bearing: 0,
        pitch: 0,
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: "900",
      },
    };

    const demoState = {
      viewState: {
        latitude: 40.7240107,
        longitude: -73.9931258,
        // longitude: -122.4,
        // latitude:37.7,
        minZoom: 10,
        zoom: 13,
        bearing: 65,
        pitch: 65,
        maxPitch: 90,
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: "1200",
      },
    };

    const flyToState = {
      viewState: {
        latitude: 40.7180561962264,
        longitude: -73.99221815018,
        // longitude: -122.4,
        // latitude:37.7,
        minZoom: 10,
        zoom: 15,
        bearing: 60,
        pitch: 45,
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: "1500",
      },
    };
    return (
      <ThemeProvider theme={theme}>
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans&family=Share+Tech+Mono&family=Roboto&family=Roboto+Condensed&family=Source+Sans+Pro&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Merriweather:wght@700;900&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Oswald&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@400;600;700;900&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Calistoga&display=swap"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="https://use.typekit.net/iph8uvh.css"/>

        <GlobalStyles />
        <div
          style={{
            position: "relative",
            top: 0,
            zIndex: 13,
            width: "100vw",
            overflowX: "hidden",
          }}
        >
          <Flex
            sx={{
              // borderBottom: '3px solid',
              // borderBottomColor: 'black',
              bg: "#93c89d",
              height: ["50px", "50px", "60px"],
              fontSize: ["13px", 2, 2],
              px: [1, 1, 2],
              color: "black",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                maxWidth: [150, 999, 999],
              }}
            >
              <Text fontWeight="bold">
                <h3>
                  <Link
                  to={"/"}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                  >Timur Abbiasov</Link>
                </h3>
              </Text>
            </Box>
            <Box mx="auto" />
          </Flex>
        </div>
        <div style={{ overflowX: "hidden" }}>
          <Flex
            sx={{
              width: "100vw",
              // height: '100vh',
              flexDirection: "column",
              // borderBottom: '3px solid',
              borderBottomColor: "black",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <BackgroundSection />
          </Flex>
        </div>
        <div className={classes.graphicContainer}>
          <div className={classes.scroller}>
            {/* [H s] How do cities reduce isolation?*/}
            <Flex sx={{
              width: "100vw",
              height:  ["200vh","200vh","175vh"],
              "@media screen and (max-height: 600px)": {
                height: "300vh"
              },
              // bg: 'red',
              
              flexDirection: 'column',
            }}>
              <Box sx={{
                // height: "100px",
                width: ['95vw','95vw','75vw'],
                "@media screen and (min-height: 1200px)": {
                  width: ['95vw','95vw','80vw'],
                },
                pr: [2,3,5],
                mt: ["10px","40px","60px"],
                position: 'sticky',
                top: 0,
                pt: sectionPaddingTop,
                fontFamily: "Source Serif Pro, serif",
                fontWeight: 700,
                alignSelf: "flex-start",
                ml: sectionPaddingLeft,
                lineHeight: sectionLineHeight,
                fontSize: sectionTextSize,
                // bg: 'blue'
              }}>
              How do cities integrate their communities and promote racial
                tolerance? Scholars and policymakers argue that parks and public
                spaces can help.
              </Box> 
              <Box sx={{
                // height: "100px",
                width: ['90vw','80vw','60vw'],
                mt: "40vh",
                "@media screen and (max-height: 600px)": {
                  top: ["37.5vh","150px","260px"],
                },
                top: ["37.5vh","160px","200px"],
                opacity: data < 3 ? 1 : 0,
                position: data < 3 ? "sticky" : "relative",
                alignSelf: "flex-start",
                ml: ["30px","45px","100px"],
                fontFamily: "Roboto Condensed, sans-serif",
                fontWeight: "normal",
                fontSize: textSize,
                lineHeight: textLineHeight,
                // bg: 'blue'
              }}>
                <q>
                    <u style={{ color: "#0E34A0" }}>By coming together</u> in
                    these relaxed settings [parks], different groups{" "}
                    <u style={{ color: "#0E34A0" }}>
                      become comfortable with each other
                    </u>
                    , thereby strengthening their communities and the democratic
                    fabric of society...  
                  </q><br/> 
                  <Popup
                    trigger={<span style={{
                      float: 'right',
                      marginTop: '4pt',
                      // marginTop: '8pt',
                      // color: "#0E34A0"
                    }}><span>Setha M. Low</span>&nbsp;&nbsp;<span style={{position : 'relative', top: ".20em"}}><GrContact/></span></span>}
                    inverted
                    wide='very'
                    style={{
                      fontFamily: "Roboto, sans-serif",
                      fontWeight: "normal",
                      color:  'rgb(210, 229, 200)'
                    }}
                    position="bottom right"
                  >
                    Professor in environmental psychology, CUNY 
                    <br/>
                    Director of the Public Space Research Group at CUNY
                  </Popup>
              </Box> 
              <Box sx={{
                // height: "100px",
                width: ['90vw','80vw','60vw'],
                mt: "40vh",
                top: ["65vh","300px","350px"],
                "@media screen and (max-height: 600px)": {
                  top: ["65vh","285px","400px"],
                },
                "@media screen and (min-height: 1280px)": {
                  top: ["55vh","275px","400px"],
                },
                alignSelf: "flex-start",
                opacity: data < 3 ? 1 : 0,
                position: data < 3 ? "sticky" : "relative",
                // ml: 4,
                ml: ["30px","45px","100px"],
                fontFamily: "Roboto Condensed, sans-serif",
                fontWeight: "normal",
                fontSize: textSize,
                lineHeight: textLineHeight,
                // bg: 'blue'
              }}>
                <q>
                    Parks and public space are essential to [...]{" "}
                    <u style={{ color: "#0E34A0" }}>promoting interaction</u>
                  </q><br/><Popup
                    trigger={<span style={{
                      marginTop: '4pt',
                      float: 'right',
                      // color: "#0E34A0"
                    }}><span>Mayor Bill de Blasio</span>&nbsp;&nbsp;<span style={{position : 'relative', top: ".20em"}}><GrContact/></span></span>}
                    inverted
                    wide
                    style={{
                      fontFamily: "Roboto, sans-serif",
                      fontWeight: "normal",
                      color:  'rgb(210, 229, 200)'
                    }}
                    position="bottom right"
                  > 
                    OneNYC 2015: New York City's Strategic Plan
                    <br/>
                    Mayor Bill de Blasio and The City of New York
                  </Popup>
              </Box> 
            </Flex> 
            {/* <Flex
              sx={{
                bg: "#93c89d",
                width: ["98vw","90vw","800px"],
                zIndex: 15,
                top: "60px",
                position: data < 3 ? "sticky" : "relative",
                py: topPad,
                mb: 4,
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  fontFamily: "Source Serif Pro, serif",
                  fontWeight: 700,
                  // textAlign: 'center',
                  // p:[1,2,2],
                  // px:[0,2,2],
                  // position: 'sticky',
                  // top:'100vh',
                  maxHeight:"120px",
                  alignSelf: "flex-start",
                  pl: ["20px","30px","60px"],
                  pt: "10px",
                  // pb: [1,2,3],
                  lineHeight: sectionLineHeight,
                  fontSize: sectionTextSize,
                }}
              >
                How do cities integrate their communities and promote racial
                tolerance? Scholars and policymakers argue that parks and public
                spaces can help.
              </Box>
            </Flex>
            {/* [s] Quote 1*/}
            {/* <Flex
              sx={{
                width: ['90vw','80vw',"70vw"],
                zIndex: 9,
                color: "#0C121D",
                top: data < 3 ? ['28vh','90px','80px'] : "auto",
                opacity: data < 3 ? 1 : 0,
                position: data < 3 ? "sticky" : "relative",
                py: topPad,
                mb: 4,
                flexDirection: "column",
              }}
            >
              <Flex
                sx={{
                  fontFamily: "Roboto Condensed, sans-serif",
                  fontWeight: "normal",
                  width: quoteBoxWidth,
                  alignSelf: "flex-start",
                  height: "60vh",
                  pl: ["30px","80px","100px"],
                  // bg:'red',
                  // opacity,
                  // pt: '40vh',
                  justifyContent: "center",
                  // mt:4,
                  fontFamily: "Roboto Condensed, sans-serif",
                  fontWeight: "normal",
                  fontSize: textSize,
                  lineHeight: textLineHeight,
                  flexDirection: "column",
                }}
              >
                
                  <q>
                    <u style={{ color: "#0E34A0" }}>By coming together</u> in
                    these relaxed settings [parks], different groups{" "}
                    <u style={{ color: "#0E34A0" }}>
                      become comfortable with each other
                    </u>
                    , thereby strengthening their communities and the democratic
                    fabric of society...       
                    
                
                  </q><Box>
                  
                </Box>
                
                <Flex
                  sx={{
                    mb: 2,
                    // bg:'red',
                    visibility: ['hidden','inherit','inherit'],
                    width: ['90vw','70vw',"55vw"],
                    mt: 3,
                    fontFamily: "Roboto Condensed, sans-serif",
                    fontWeight: 400,
                    fontSize: ["18px", "18px", "20px"],
                    alignSelf: "flex-start",
                    pl: [2,3,4],
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-end",
                    lineHeight: ["20x", "20px", "22px"],
                  }}
                >
                  <span>
                    Professor in environmental psychology, CUNY \n Director of the Public Space Research Group at CUNY
                  </span>
                </Flex>
              </Flex>
            </Flex> */}
            {/* [s] Quote 2*/}
            {/* <Flex
              sx={{
                width: ['90vw','80vw',"70vw"],
                color: "#0C121D",
                zIndex: 9,
                top: data < 3 ? ['46vh','255px','250px'] : "auto",
                opacity: data < 3 ? 1 : 0,
                position: data < 3 ? "sticky" : "relative",
                py: topPad,
                mb: 4,
                flexDirection: "column",
              }}
            >
              <Flex
                sx={{
                  fontFamily: "Roboto Condensed, sans-serif",
                  fontWeight: "normal",
                  width: quoteBoxWidth,
                  alignSelf: "flex-start",
                  height: "60vh",
                  pl: ["30px","80px","100px"],
                  // opacity,
                  // pt: '40vh',
                  justifyContent: "center",
                  // mt:4,
                  fontSize: textSize,
                  lineHeight: textLineHeight,
                  flexDirection: "column",
                }}
              >
                <Box>
                  <q>
                    Parks and public space are essential to [...]{" "}
                    <u style={{ color: "#0E34A0" }}>promoting interaction</u>
                  </q>{" "}
                </Box>
                <Flex
                  sx={{
                    mb: 2,
                    // bg:'red',
                    width: ['80vw','70vw',"55vw"],
                    mt: 3,
                    fontFamily: "Roboto Condensed, sans-serif",
                    fontWeight: 400,
                    fontSize: ["18px", "18px", "20px"],
                    alignSelf: "flex-start",
                    pl: 4,
                    visibility: ['hidden','inherit','inherit'],
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-end",
                    lineHeight: ["20x", "20px", "22px"],
                  }}
                >
                  <span>
                    <span style={{}}>
                      OneNYC 2015: New York City's Strategic Plan
                    </span>
                  </span>
                  <span>Mayor Bill de Blasio and The City of New York</span>
                </Flex>
              </Flex>
            </Flex> */}
            {/* [H s] In this project I estimate the effect of parks on diversity */}
            {/* <Flex
              sx={{
                width: ["90vw","80vw","60vw"],
                color: "#0C121D",
                // bg: 'red',
                zIndex: 9,
                top: data < 3 ? ["55vh","50vh","50vh"] : "auto",
                opacity: data < 3 ? 1 : 0,
                position: data < 3 ? "sticky" : "relative",
                py: topPad,
                mb: 4,
                flexDirection: "column",
              }}
            >
              <Flex
                sx={{
                  fontFamily: "Roboto Condensed, sans-serif",
                  alignSelf: "flex-start",
                  // color: 'eblue',
                  height: "60vh",
                  justifyContent: "center",
                  fontSize: textSize,
                  lineHeight: textLineHeight,
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    borderLeft: "solid 8px",
                    borderColor: "eblue",
                    color: "black",
                    // p:2
                    // ml:0,
                    // bg:'red',
                    pl: ["22px","52px","52px"],
                  }}
                >
                  In this project, I investigate whether access to park space
                  has a causal effect on the daily-life diversity experienced by
                  New Yorkers.
                </Box>
              </Flex>
            </Flex> */}
            {/* [s] Map Box (st) */}
            <Flex
              sx={{
                width: ["100vw","98vw","98vw"],
                pr: [0,2,2],
                pl: [2,0,0],
                zIndex: -1,
                // opacity: (data <= 3 ) ? 0 : 1,
                display: data <= 3 ? "none" : "flex",
                // bg:'red',
                height:["75vh","70vh","80vh"],
                transition: "all 0.2s ease-in",
                top: ["23vh","20vh","10vh"],
                position: "sticky",
                my: "30px",
                flexDirection: "row",
                justifyContent: ["center","flex-end","flex-end"],
                alignItems: ['flex-start','center',"center"],
              }}
            >
              
              <Flex
                sx={{
                  flexDirection: "column",
                  // borderTop: '3px solid #0C121D',
                  // borderBottom: '3px solid #0C121D',
                  // p:2,

                  transition: "all 0.2s ease-in",
                  // bg:'red',
                  width: ["80vw","60vw","44vw"],
                  "@media screen and (min-height: 1200px)": {
                    width: ["80vw","60vw","60vw"],
                  },
                  alignItems: "center",
                  // pb: [1,2,3],
                  justifyContent: "center",
                  lineHeight: ["32px", "36px", "34px"],
                  fontSize: ["30px", "32px", "30px"],
                }}
              >
                <Flex
                sx={{
                  width: ["80vw","60vw","40vw"],
                  "@media screen and (min-height: 1200px)": {
                    width: data <= 4? 0: ["80vw","60vw","60vw"],
                  },
                  // mr: ["-52w","-52vw","-400px"],
                  pt: ["30px","30px","26px"],
                  pl: "1",
                  flexDirection: "row",
                  // mt: ["-200px","-45vh","-49vh"],
                  // color: "eblue",
                  // bg: 'eblue',
                  height: "60px",
                }}
                >
                    
                <ColorLegend page={data} />
                </Flex>
                <Box
                  sx={{
                    height:["60vh","70vh","80vh"],
                    width: data > 4? 0: ["80vw","60vw","40vw"],
                    "@media screen and (max-height: 600px)": {
                      height: "65vh"
                    },
                    "@media screen and (min-height: 1200px)": {
                      height: "65vh",
                      width: data > 4? 0: ["80vw","60vw","60vw"],
                    },
                    // bg: "blue",
                    mt: ["80vh","70vh","80vh"],
                    height:["60vh","70vh","80vh"],
                    display: data <= 4 ? "block" : "none",
                  }}
                >
                  {/* <Image /> */}
                </Box>
                <Flex
                  sx={{
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    // display: data <= 4 ? "none": "block",
                    opacity: data <= 4 ? 0 : 1,
                    // mt: ["-60px","-60px","-60px"],
                    // width: (data <= 4 ) ? 0 : 'auto',
                    transition: "all 0.3s ease-in",
                    height:["60vh","70vh","80vh"],
                    width: data <= 4? 0: ["80vw","60vw","40vw"],
                    "@media screen and (max-height: 600px)": {
                      height: "65vh"
                    },
                    "@media screen and (min-height: 1200px)": {
                      height: "65vh",
                      width: data <= 4? 0: ["80vw","60vw","60vw"],
                    },
                    zIndex: "20",
                    // bg: 'yellow',
                  }}
                >
                  <ParksMap
                    viewState={
                      data > 6
                        ? data > 8
                          ? summaryView
                          : flyToState
                        : initialView
                    }
                    page={data - 2}
                  />
        
                  
                </Flex>
              </Flex>
            </Flex>
            {/* [s] Quantifying the Everyday Encounters Using GPS Data from Social
                Media.(st)*/}
            <Flex
              sx={{
                bg: "#93c89d",
                width: data > 9? ["99vw","600px","800px"] : ["99vw","600px","50vw"],
                mt: "-80vh",
                "@media screen and (min-height: 1200px)": {
                  width: ["99vw","600px","65vw"],
                },
                // bg:'red',
                zIndex: 15,
                top: 0,
                position: "sticky",
                // pt: topPad, 
                pb: ["15px","15px","15px"],
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  fontFamily: "Source Serif Pro, serif",
                  fontWeight: 700,
                  // color: this.props.page > 2? 'red': 'blue',
                  alignSelf: "flex-start",
                  pl: sectionPaddingLeft,
                  pt: sectionPaddingTop,
                  lineHeight: sectionLineHeight,
                  fontSize: sectionTextSize,
                }}
              >
             
                  <Box sx={{}}>
                    {sectionHeaders[data > 9? 1 : 0]}
                  </Box>
                
                
              </Box>
            </Flex>
            {/* Scrollama 1: Quantifying diversity */}
            <Scrollama
                style={{ marginTop: "15vh"}}
                onStepEnter={this.onStepEnter}
                onStepExit={this.onStepExit}
                progress
                onStepProgress={this.onStepProgress}
                offset={1}
                //   debug
              >
                {steps.map((value) => {
                  const isVisible = value === data;
                  const visibility = isVisible ? "visible" : "hidden";
                  return (
                    <Step data={value} key={value}>
                      <div>
                        <Spring
                          delay={0}
                          config={{ tension: 280, friction: 30 }}
                          to={{ opacity: isVisible ? 1 : 0 }}
                        >
                          {({ opacity }) => (
                            <Flex
                              sx={{
                                fontFamily: "Roboto Condensed, sans-serif",
                                fontWeight: "normal",
                                width: ["75vw","50wv","45vw"],
                                // bg: value == 10 ? "red" : (value == 9? "blue" : "100vh"),
                                // border: '1px solid black',
                                alignSelf: "flex-end",
                                height: value == 10 ? "40vh" : (value == 9? "100vh" : "100vh"),
                                "@media screen and (max-height: 600px)": {
                                  height: value == 10 ? "80vh" : (value == 9? "100vh" : "100vh")
                                },
                                pl: ["20px","40px","60px"],
                                "@media screen and (min-height: 1200px)": {
                                  pl: ["20px","20px","40px"],
                                  width: ["75vw","50wv","35vw"],
                                },
                                // opacity,
                                // pt: '40vh',
                                justifyContent: "flex-end",
                                // mt:4,
                                fontSize: textSize,
                                lineHeight: textLineHeight,
                                flexDirection: "column",
                              }}
                            >
                              <Box sx={{
                                bg: [value <= 9? "rgba(210, 229, 200, 0.75)": 'rgba(0,0,0,0)', value <= 9? "rgba(210, 229, 200, 0.75)": 'rgba(0,0,0,0)','rgba(0,0,0,0)'],
                                "@media screen and (max-height: 600px)": {
                                  bg: value <= 9? "rgba(210, 229, 200, 0.75)": 'rgba(0,0,0,0)'
                                },
                                "@media screen and (min-height: 1200px)": {
                                  bg: value <= 9? "rgba(210, 229, 200, 0.75)": 'rgba(0,0,0,0)'
                                },
                                "@media screen and (max-width: 800px)": {
                                  bg: value <= 9? "rgba(210, 229, 200, 0.75)": 'rgba(0,0,0,0)'
                                },
                                p: [3,3,3],
                              }}>
                                {slides[value - 3]}
                              </Box>
                            </Flex>
                          )}
                        </Spring>
                      </div>
                    </Step>
                  );
                })}
              </Scrollama>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default injectSheet(styles)(Demo);
