import React, { useState, useCallback } from "react";
import DeckGL, { FlyToInterpolator } from "deck.gl";
import { StaticMap } from "react-map-gl";
import { Box, Flex, Text } from "rebass";
import ParksData from "../data/parks_nyc.json";
import UserHomesData from "../data/user_homes.json";
import {
  GeoJsonLayer,
  ScatterplotLayer,
  PolygonLayer,
  ArcLayer,
  TextLayer
} from "@deck.gl/layers";
import UserDestinationsData from "../data/user_destinations.json";
import InteractionData from "../data/interaction_data_parks.json";

import {
  easeCubicInOut,
  easeExpIn,
  easeExpInOut,
  easeCircleInOut,
} from "d3-ease";

function getData(jsonData) {
  return Object.values(jsonData);
}

class MapApp extends React.Component {
  render() {
    const { viewState } = this.props.viewState;
    const page = this.props.page;
    const visible = this.props.visibility;
    const destinationsData = getData(UserDestinationsData);
    const userInteractionsData = getData(InteractionData);
    const data = [
      {
        contour: [
          [
            [-73.9966068535, 40.7296682143],
            [-73.9972794739, 40.7299988332],
            [-73.9973742454, 40.7300454178],
            [-73.9979696108, 40.7303380546],
            [-73.9980913216, 40.7303978774],
            [-73.9994743926, 40.7310776696],
            [-73.9985945216, 40.7321147623],
            [-73.9972790919, 40.7314682135],
            [-73.9967815834, 40.7312144572],
            [-73.9957287217, 40.730703207],
            [-73.9966068535, 40.7296682143],
          ],
        ],

        zipcode: 94107,
        population: 26599,
        area: 6.11,
      },
    ];
    const majorGroups = ['white',
    'black or african american',
    'hispanic, latino, or spanish origin',
    'asian'];
    const tripPointLayer = new ScatterplotLayer({
      id: "trip-point-layer",
      data: destinationsData,
      stroked: false,
      visible: page <=6,
      transitions: {
        getFillColor: {
          duration: 200,
        },
      },
      pickable: false,
      filled: true,
      getFillColor: (d) => [14, 52, 160, (page > 4) & (page < 6) ? 255 : 255],
      getPosition: (d) => d.destination_point_geom.coordinates,
      getRadius: 5,
    });

    const tripArcLayer = new ArcLayer({
      id: "trip-arcs-layer",
      data: destinationsData,
      transitions: {
        getTargetPosition: {
          duration: 1400,
          easing: easeExpIn,
        },
        getWidth: {
          duration: 300,
          easing: easeExpInOut,
        },
        getHeight: {
          duration: 300,
          easing: easeExpInOut,
        },
      },
      opacity: 0.6,
      visible: page <=6,
      getWidth: page > 4 ? (page > 5 ? 2 : 2) : 0,
      // getHeight:(page > 4? (page > 5? 0.2: 1): 0),
      getSourcePosition: (d) => d.source_point_geom.coordinates,
      getTargetPosition: (d) =>
        page > 4
          ? d.destination_point_geom.coordinates
          : d.source_point_geom.coordinates,
      // getTargetPosition: d => [
      //   (page > 4? 0 : 1) * d.source_point_geom.coordinates[0] + (page > 4? 1: 0) * d.destination_point_geom.coordinates[0],
      //   (page > 4? 0 : 1) * d.source_point_geom.coordinates[1] + (page > 4? 1: 0) * d.destination_point_geom.coordinates[1],
      // ],
      getSourceColor: (d) => [14, 52, 160],
      getTargetColor: (d) => [14, 52, 160],
      // tilt : 30,
      pickable: false,
    });
    
    const targetBoxesLayer = new PolygonLayer({
      id: "target-boxes-layer",
      data: destinationsData,
      stroked: true,
      filled: true,
      transitions: {
        getFillColor: {
          duration: 200,
        },
        getLineColor: {
          duration: 1000,
        }
      },
      // visible: page <=6,
      getPolygon: (d) => d.destination_box_geom.coordinates,
      getLineWidth: 4,
      getFillColor: (d) => [14, 52, 160, (page > 4) & (page < 6) ? 30 : 0],
      getLineColor: [14, 52, 160, (page <= 6) ? 255 : 0],
      // getFillColor: d => d.in_park ? [50, 232, 117, 20] : [14, 52, 160, 20],
      // getLineColor: d => d.in_park ? [50, 232, 117] : [14, 52, 160, 255],
    });
    const baselinePos = [-73.982201553, 40.7270567801];
    const interactionsLayer = new ScatterplotLayer({
      id: "interacions-layer",
      data: userInteractionsData,
      stroked: false,
      pickable: false,
      filled: true,
      transitions: {
        getFillColor: {
          duration: 400,
          easing: easeExpInOut,
        },
        getPosition: {
          duration: 900,
        },
      },
      radiusScale: 1,
      getFillColor: (d) => [
        d.color[0],
        d.color[1],
        d.color[2],
        page <= 5 ? 0 : (page > 7 ? (d.inPark>0 | d.index === 796 ? 255:40 ) : 255),
      ],
      getPosition: (d) => (page > 6 ? ((page > 7 & d.inPark === 1)? [d.geometryAlt.geometry.coordinates[0],baselinePos[1]+(majorGroups.indexOf(d.inferred_race)-1)*0.00025]: d.geometryAlt.geometry.coordinates) : d.geometry.coordinates),
      radiusMaxPixels: 100,
      getRadius: d => (page > 6 ? (d.index === 796 ? 15: 8.5) : 7.5),
    });

    const sharesLayer = new TextLayer({
      id: 'text-layer',
      data: [
        {name: '13%', coordinates: [-73.9938, 40.7213172596095],color: [252, 75, 56]},
        {name: '14%', coordinates: [-73.9938, 40.7221], color: [80, 128, 234]},
        {name: '20%', coordinates: [-73.9938, 40.723], color: [244, 133, 0]},
        {name: '53%', coordinates: [-73.9938, 40.7247], color: [29, 168, 39]}
      ],
      pickable: true,
      visible: page > 5,
      transitions: {
        getColor: {
          duration: 1400,
          easing: easeExpIn,
        },
      },
      getPosition: d => d.coordinates,
      getText: d =>  d.name,
      getColor: d => [d.color[0],d.color[1],d.color[2],(page > 6 & page < 8)? 255: 0],
      fontFamily: 'Roboto Slab, serif',
      getSize: 32,
      getAngle: 0,
      getTextAnchor: 'middle',
      getAlignmentBaseline: 'center'
    });

    const encounterLabelLayer = new TextLayer({
      id: 'label-layer',
      data: [
        {
          name: 'Encounters\nin parks', 
          coordinates: [-73.9927141,baselinePos[1]+0.0001],
          color: [0, 0, 0]
        }
    ],
      pickable: true,
      visible: page > 7,
      transitions: {
        getColor: {
          duration: 1400,
          easing: easeExpIn,
        },
      },
      getPosition: d => d.coordinates,
      getText: d =>  d.name,
      getColor: d => [d.color[0],d.color[1],d.color[2],(page >= 8)? 255: 0],
      fontFamily: 'Roboto Condensed, sans-serif',
      getSize: 24,
      getAngle: 0,
      getTextAnchor: 'start',
      getAlignmentBaseline: 'center'
    });
    
    const parksLayer = new GeoJsonLayer({
      id: "parks-layer",
      data: getData(ParksData),
      pickable: true,
      stroked: false,
      filled: true,
      lineWidthMinPixels: 1,
      getFillColor: [75, 189, 0, 255],
      getLineColor: [80, 80, 80],
      opacity: 0.1,
      getLineWidth: 2,
    });

    const userHomesLayer = new ScatterplotLayer({
      id: "user-homes-layer",
      data: getData(UserHomesData),
      pickable: true,
      opacity: 0.8,
      stroked: false,
      filled: true,
      transitions: {
        getRadius: {
          type: "spring",
          stiffness: 0.01,
          damping: 0.15,
        },
        getFillColor: {
          type: "spring",
          stiffness: 0.01,
          damping: 0.15,
        },
      },
      radiusScale: 10,
      updateTriggers: {
        getRadius: [page],
        getFillColor: [page],
      },
      radiusMinPixels: 0,
      radiusMaxPixels: 100,
      getPosition: (d) => d.coordinates,
      getRadius: page <= 2 ? 1.5 : page === 4 ? 6 : page > 4 ? 0 : 4,
      getFillColor: page === 3 ? [135, 151, 144] : (d) => d.color,
    });

    if (page <= 1 | page > 8) {
      return null;
    } else {
      return (
        <DeckGL
          initialViewState={viewState}
          // height = "70vh"
          // width="60vw"
          layers={
            page > 0
              ? page > 3
                ?  [
                    userHomesLayer,
                    targetBoxesLayer,
                    tripPointLayer,
                    tripArcLayer,
                    interactionsLayer,
                    sharesLayer,
                    encounterLabelLayer,
                  ]
                : [userHomesLayer]
              : []
          }
          style={{
            position: "relative",
            display: page <= 2 ? "none" : "initial",
            transition: "all 0.3s ease-in",
          }}
          controller={true}
        >
          <StaticMap
            visible={page <= 6 }
            mapboxApiAccessToken={process.env.GATSBY_MAPBOX_TOKEN}
            mapStyle={"mapbox://styles/tabbiasov/ckgu1cq7p3f8019l6tyet8tfq"}
          />
        </DeckGL>
      );
    }
  }
}

export default MapApp;
